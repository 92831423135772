<template>
  <fragment>
    <!--LIST-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxBookingVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxBookingEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxBookingCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxBookingDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showBooking = false
      if (this.sourcePermisos[this.index].all.checkboxBookingVer) {
        this.sourcePermisos[this.index].permisos.push('cotizador:list')
        this.sourcePermisos[this.index].permisos.push('reservas:list')
        this.sourcePermisos[this.index].permisos.push('pvp:list')
        this.sourcePermisos[this.index].permisos.push('tarifarios:list')
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'cotizador:list' && e !== 'reservas:list' && e !== 'pvp:list' && e !== 'tarifarios:list',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showBooking = !this.$store.state.app.showBooking
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showBooking = false
      if (this.sourcePermisos[this.index].all.checkboxBookingEdit) {
        this.sourcePermisos[this.index].permisos.push('cotizador:edit')
        this.sourcePermisos[this.index].permisos.push('reservas:edit')
        this.sourcePermisos[this.index].permisos.push('pvp:edit')
        this.sourcePermisos[this.index].permisos.push('tarifarios:edit')
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'cotizador:edit' && e !== 'reservas:edit' && e !== 'pvp:edit' && e !== 'tarifarios:edit',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showBooking = !this.$store.state.app.showBooking
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showBooking = false
      if (this.sourcePermisos[this.index].all.checkboxBookingCreate) {
        this.sourcePermisos[this.index].permisos.push('cotizador:create')
        this.sourcePermisos[this.index].permisos.push('reservas:create')
        this.sourcePermisos[this.index].permisos.push('pvp:create')
        this.sourcePermisos[this.index].permisos.push('tarifarios:create')
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'cotizador:create' && e !== 'reservas:create' && e !== 'pvp:create' && e !== 'tarifarios:create',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showBooking = !this.$store.state.app.showBooking
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showBooking = false
      if (this.sourcePermisos[this.index].all.checkboxBookingDelete) {
        this.sourcePermisos[this.index].permisos.push('cotizador:destroy')
        this.sourcePermisos[this.index].permisos.push('reservas:destroy')
        this.sourcePermisos[this.index].permisos.push('pvp:destroy')
        this.sourcePermisos[this.index].permisos.push('tarifarios:destroy')
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'cotizador:destroy' && e !== 'reservas:destroy' && e !== 'pvp:destroy' && e !== 'tarifarios:destroy',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showBooking = !this.$store.state.app.showBooking
      }, 10)
    },
  },
}
</script>
