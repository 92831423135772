<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladores = false
      this.$store.state.app.showNomencladoresFlights = false
      this.$store.state.app.showNomencladoresCars = false
      this.$store.state.app.showNomencladoresHotels = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresVer) {
        this.sourcePermisos[this.index].permisos.push('nom_aerolines:list')
        this.sourcePermisos[this.index].permisos.push('airplane:list')
        this.sourcePermisos[this.index].permisos.push('nom_clase_flights:list')
        this.sourcePermisos[this.index].permisos.push('nom_tarifa_flights:list')
        this.sourcePermisos[this.index].permisos.push('nom_dimensiones_flights:list')
        this.sourcePermisos[this.index].permisos.push('nom_peso_equipaje_flights:list')
        this.sourcePermisos[this.index].permisos.push('airport:list')
        this.sourcePermisos[this.index].permisos.push('nom_tarjeta_fidelidad_flights:list')
        this.sourcePermisos[this.index].permisos.push('nom_code_ofac_flights:list')
        this.sourcePermisos[this.index].permisos.push('nom_politica_tarifaria_flights:list')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_fligths:list')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_fligths:list')

        this.sourcePermisos[this.index].permisos.push('nom_transmision_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_type_auto_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_marca_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_model_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_combustible_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_caracteristica_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_categories_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_rentadora_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_x_km_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_edades_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_tags_pto_recogida_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_oficina_renta_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_cars:list')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_cars:list')

        this.sourcePermisos[this.index].permisos.push('nom_tipo_destino_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_operador_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_marca_asociada_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_categorias_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_intereses_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_servicios_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_habitacion_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_regimen_alimenticio:list')
        this.sourcePermisos[this.index].permisos.push('nom_edad_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_reducciones_contrate_hotels:list')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_hotels:list')

        this.sourcePermisos[this.index].permisos.push('nom_categories_conditions:list')
        this.sourcePermisos[this.index].permisos.push('tipo_afiliado:list')

        this.sourcePermisos[this.index].all.checkboxNomencladoresCarsVer = true
        this.sourcePermisos[this.index].all.checkboxNomencladoresFlightVer = true
        this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsVer = true
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_aerolines:list'
            && e !== 'airplane:list'
            && e !== 'nom_clase_flights:list'
            && e !== 'nom_tarifa_flights:list'
            && e !== 'nom_dimensiones_flights:list'
            && e !== 'nom_peso_equipaje_flights:list'
            && e !== 'airport:list'
            && e !== 'nom_tarjeta_fidelidad_flights:list'
            && e !== 'nom_code_ofac_flights:list'
            && e !== 'nom_politica_tarifaria_flights:list'
            && e !== 'nom_suplemento_contrate_fligths:list'
            && e !== 'nom_conditions_generals_fligths:list'
            && e !== 'nom_transmision_cars:list'
            && e !== 'nom_type_auto_cars:list'
            && e !== 'nom_marca_cars:list'
            && e !== 'nom_model_cars:list'
            && e !== 'nom_combustible_cars:list'
            && e !== 'nom_caracteristica_cars:list'
            && e !== 'nom_categories_cars:list'
            && e !== 'nom_rentadora_cars:list'
            && e !== 'nom_tarifas_cars:list'
            && e !== 'nom_tarifas_x_km_cars:list'
            && e !== 'nom_edades_cars:list'
            && e !== 'nom_tags_pto_recogida_cars:list'
            && e !== 'nom_oficina_renta_cars:list'
            && e !== 'nom_tag_imagen_cars:list'
            && e !== 'nom_suplemento_contrate_cars:list'
            && e !== 'nom_conditions_generals_cars:list'
            && e !== 'nom_tipo_destino_hotels:list'
            && e !== 'nom_operador_hotels:list'
            && e !== 'nom_marca_asociada_hotels:list'
            && e !== 'nom_categorias_hotels:list'
            && e !== 'nom_intereses_hotels:list'
            && e !== 'nom_servicios_hotels:list'
            && e !== 'nom_habitacion_hotels:list'
            && e !== 'nom_regimen_alimenticio:list'
            && e !== 'nom_edad_hotels:list'
            && e !== 'nom_tag_imagen_hotels:list'
            && e !== 'nom_suplemento_contrate_hotels:list'
            && e !== 'nom_reducciones_contrate_hotels:list'
            && e !== 'nom_conditions_generals_hotels:list'
            && e !== 'nom_categories_conditions:list'
            && e !== 'tipo_afiliado:list',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresFlightVer = false
        this.sourcePermisos[this.index].all.checkboxNomencladoresCarsVer = false
        this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsVer = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladores = !this.$store.state.app.showNomencladores
        this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladores = false
      this.$store.state.app.showNomencladoresFlights = false
      this.$store.state.app.showNomencladoresCars = false
      this.$store.state.app.showNomencladoresHotels = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresEdit) {
        this.sourcePermisos[this.index].permisos.push('nom_aerolines:edit')
        this.sourcePermisos[this.index].permisos.push('airplane:edit')
        this.sourcePermisos[this.index].permisos.push('airport:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tarjeta_fidelidad_flights:edit')
        this.sourcePermisos[this.index].permisos.push('nom_code_ofac_flights:edit')
        this.sourcePermisos[this.index].permisos.push('nom_politica_tarifaria_flights:edit')
        this.sourcePermisos[this.index].permisos.push('nom_clase_flights:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tarifa_flights:edit')
        this.sourcePermisos[this.index].permisos.push('nom_dimensiones_flights:edit')
        this.sourcePermisos[this.index].permisos.push('nom_peso_equipaje_flights:edit')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_fligths:edit')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_fligths:edit')

        this.sourcePermisos[this.index].permisos.push('nom_transmision_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_type_auto_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_marca_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_model_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_combustible_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_caracteristica_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_categories_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_rentadora_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_x_km_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_edades_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tags_pto_recogida_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_oficina_renta_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_cars:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_cars:edit')

        this.sourcePermisos[this.index].permisos.push('nom_tipo_destino_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_operador_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_marca_asociada_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_categorias_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_intereses_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_servicios_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_habitacion_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_regimen_alimenticio:edit')
        this.sourcePermisos[this.index].permisos.push('nom_edad_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_reducciones_contrate_hotels:edit')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_hotels:edit')

        this.sourcePermisos[this.index].permisos.push('nom_categories_conditions:edit')
        this.sourcePermisos[this.index].permisos.push('tipo_afiliado:edit')

        this.sourcePermisos[this.index].all.checkboxNomencladoresFlightEdit = true
        this.sourcePermisos[this.index].all.checkboxNomencladoresCarsEdit = true
        this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsEdit = true
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_aerolines:edit'
            && e !== 'airplane:edit'
            && e !== 'airport:edit'
            && e !== 'nom_tarjeta_fidelidad_flights:edit'
            && e !== 'nom_code_ofac_flights:edit'
            && e !== 'nom_politica_tarifaria_flights:edit'
            && e !== 'nom_clase_flights:edit'
            && e !== 'nom_tarifa_flights:edit'
            && e !== 'nom_dimensiones_flights:edit'
            && e !== 'nom_peso_equipaje_flights:edit'
            && e !== 'nom_suplemento_contrate_fligths:edit'
            && e !== 'nom_conditions_generals_fligths:edit'
            && e !== 'nom_transmision_cars:edit'
            && e !== 'nom_type_auto_cars:edit'
            && e !== 'nom_marca_cars:edit'
            && e !== 'nom_model_cars:edit'
            && e !== 'nom_combustible_cars:edit'
            && e !== 'nom_caracteristica_cars:edit'
            && e !== 'nom_categories_cars:edit'
            && e !== 'nom_rentadora_cars:edit'
            && e !== 'nom_tarifas_cars:edit'
            && e !== 'nom_tarifas_x_km_cars:edit'
            && e !== 'nom_edades_cars:edit'
            && e !== 'nom_tags_pto_recogida_cars:edit'
            && e !== 'nom_oficina_renta_cars:edit'
            && e !== 'nom_tag_imagen_cars:edit'
            && e !== 'nom_suplemento_contrate_cars:edit'
            && e !== 'nom_conditions_generals_cars:edit'
            && e !== 'nom_tipo_destino_hotels:edit'
            && e !== 'nom_operador_hotels:edit'
            && e !== 'nom_marca_asociada_hotels:edit'
            && e !== 'nom_categorias_hotels:edit'
            && e !== 'nom_intereses_hotels:edit'
            && e !== 'nom_servicios_hotels:edit'
            && e !== 'nom_habitacion_hotels:edit'
            && e !== 'nom_regimen_alimenticio:edit'
            && e !== 'nom_edad_hotels:edit'
            && e !== 'nom_tag_imagen_hotels:edit'
            && e !== 'nom_suplemento_contrate_hotels:edit'
            && e !== 'nom_reducciones_contrate_hotels:edit'
            && e !== 'nom_conditions_generals_hotels:edit'
            && e !== 'nom_categories_conditions:edit'
            && e !== 'tipo_afiliado:edit',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresFlightEdit = false
        this.sourcePermisos[this.index].all.checkboxNomencladoresCarsEdit = false
        this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsEdit = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladores = !this.$store.state.app.showNomencladores
        this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladores = false
      this.$store.state.app.showNomencladoresFlights = false
      this.$store.state.app.showNomencladoresCars = false
      this.$store.state.app.showNomencladoresHotels = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresCreate) {
        this.sourcePermisos[this.index].permisos.push('nom_aerolines:create')
        this.sourcePermisos[this.index].permisos.push('airplane:create')
        this.sourcePermisos[this.index].permisos.push('airport:create')
        this.sourcePermisos[this.index].permisos.push('nom_tarjeta_fidelidad_flights:create')
        this.sourcePermisos[this.index].permisos.push('nom_code_ofac_flights:create')
        this.sourcePermisos[this.index].permisos.push('nom_politica_tarifaria_flights:create')
        this.sourcePermisos[this.index].permisos.push('nom_clase_flights:create')
        this.sourcePermisos[this.index].permisos.push('nom_tarifa_flights:create')
        this.sourcePermisos[this.index].permisos.push('nom_dimensiones_flights:create')
        this.sourcePermisos[this.index].permisos.push('nom_peso_equipaje_flights:create')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_fligths:create')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_fligths:create')

        this.sourcePermisos[this.index].permisos.push('nom_transmision_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_type_auto_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_marca_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_model_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_combustible_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_caracteristica_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_categories_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_rentadora_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_x_km_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_edades_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_tags_pto_recogida_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_oficina_renta_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_cars:create')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_cars:create')

        this.sourcePermisos[this.index].permisos.push('nom_tipo_destino_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_operador_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_marca_asociada_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_categorias_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_intereses_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_servicios_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_habitacion_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_regimen_alimenticio:create')
        this.sourcePermisos[this.index].permisos.push('nom_edad_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_reducciones_contrate_hotels:create')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_hotels:create')

        this.sourcePermisos[this.index].permisos.push('nom_categories_conditions:create')
        this.sourcePermisos[this.index].permisos.push('tipo_afiliado:create')

        this.sourcePermisos[this.index].all.checkboxNomencladoresFlightCreate = true
        this.sourcePermisos[this.index].all.checkboxNomencladoresCarsCreate = true
        this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsCreate = true
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_aerolines:create'
            && e !== 'airplane:create'
            && e !== 'airport:create'
            && e !== 'nom_tarjeta_fidelidad_flights:create'
            && e !== 'nom_code_ofac_flights:create'
            && e !== 'nom_politica_tarifaria_flights:create'
            && e !== 'nom_clase_flights:create'
            && e !== 'nom_tarifa_flights:create'
            && e !== 'nom_dimensiones_flights:create'
            && e !== 'nom_peso_equipaje_flights:create'
            && e !== 'nom_suplemento_contrate_fligths:create'
            && e !== 'nom_conditions_generals_fligths:create'
            && e !== 'nom_transmision_cars:create'
            && e !== 'nom_type_auto_cars:create'
            && e !== 'nom_marca_cars:create'
            && e !== 'nom_model_cars:create'
            && e !== 'nom_combustible_cars:create'
            && e !== 'nom_caracteristica_cars:create'
            && e !== 'nom_categories_cars:create'
            && e !== 'nom_rentadora_cars:create'
            && e !== 'nom_tarifas_cars:create'
            && e !== 'nom_tarifas_x_km_cars:create'
            && e !== 'nom_edades_cars:create'
            && e !== 'nom_tags_pto_recogida_cars:create'
            && e !== 'nom_oficina_renta_cars:create'
            && e !== 'nom_suplemento_contrate_cars:create'
            && e !== 'nom_conditions_generals_cars:create'
            && e !== 'nom_tag_imagen_cars:create'
            && e !== 'nom_tipo_destino_hotels:create'
            && e !== 'nom_operador_hotels:create'
            && e !== 'nom_marca_asociada_hotels:create'
            && e !== 'nom_categorias_hotels:create'
            && e !== 'nom_intereses_hotels:create'
            && e !== 'nom_servicios_hotels:create'
            && e !== 'nom_habitacion_hotels:create'
            && e !== 'nom_regimen_alimenticio:create'
            && e !== 'nom_edad_hotels:create'
            && e !== 'nom_tag_imagen_hotels:create'
            && e !== 'nom_suplemento_contrate_hotels:create'
            && e !== 'nom_reducciones_contrate_hotels:create'
            && e !== 'nom_conditions_generals_hotels:create'
            && e !== 'nom_categories_conditions:create'
            && e !== 'tipo_afiliado:create',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresFlightCreate = false
        this.sourcePermisos[this.index].all.checkboxNomencladoresCarsCreate = false
        this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsCreate = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladores = !this.$store.state.app.showNomencladores
        this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladores = false
      this.$store.state.app.showNomencladoresFlights = false
      this.$store.state.app.showNomencladoresCars = false
      this.$store.state.app.showNomencladoresHotels = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresDelete) {
        this.sourcePermisos[this.index].permisos.push('nom_aerolines:destroy')
        this.sourcePermisos[this.index].permisos.push('airplane:destroy')
        this.sourcePermisos[this.index].permisos.push('airport:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tarjeta_fidelidad_flights:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_code_ofac_flights:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_politica_tarifaria_flights:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_clase_flights:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tarifa_flights:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_dimensiones_flights:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_peso_equipaje_flights:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_fligths:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_fligths:destroy')

        this.sourcePermisos[this.index].permisos.push('nom_transmision_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_type_auto_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_marca_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_model_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_combustible_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_caracteristica_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_categories_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_rentadora_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tarifas_x_km_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_edades_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tags_pto_recogida_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_oficina_renta_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_cars:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_cars:destroy')

        this.sourcePermisos[this.index].permisos.push('nom_tipo_destino_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_operador_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_marca_asociada_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_categorias_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_intereses_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_servicios_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_habitacion_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_regimen_alimenticio:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_edad_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_reducciones_contrate_hotels:destroy')
        this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_hotels:destroy')

        this.sourcePermisos[this.index].permisos.push('nom_categories_conditions:destroy')
        this.sourcePermisos[this.index].permisos.push('tipo_afiliado:destroy')

        this.sourcePermisos[this.index].all.checkboxNomencladoresFlightDelete = true
        this.sourcePermisos[this.index].all.checkboxNomencladoresCarsDelete = true
        this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsDelete = true
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_aerolines:destroy'
            && e !== 'airplane:destroy'
            && e !== 'airport:destroy'
            && e !== 'nom_tarjeta_fidelidad_flights:destroy'
            && e !== 'nom_code_ofac_flights:destroy'
            && e !== 'nom_politica_tarifaria_flights:destroy'
            && e !== 'nom_clase_flights:destroy'
            && e !== 'nom_tarifa_flights:destroy'
            && e !== 'nom_dimensiones_flights:destroy'
            && e !== 'nom_peso_equipaje_flights:destroy'
            && e !== 'nom_suplemento_contrate_fligths:destroy'
            && e !== 'nom_conditions_generals_fligths:destroy'
            && e !== 'nom_transmision_cars:destroy'
            && e !== 'nom_type_auto_cars:destroy'
            && e !== 'nom_marca_cars:destroy'
            && e !== 'nom_model_cars:destroy'
            && e !== 'nom_combustible_cars:destroy'
            && e !== 'nom_caracteristica_cars:destroy'
            && e !== 'nom_categories_cars:destroy'
            && e !== 'nom_rentadora_cars:destroy'
            && e !== 'nom_tarifas_cars:destroy'
            && e !== 'nom_tarifas_x_km_cars:destroy'
            && e !== 'nom_edades_cars:destroy'
            && e !== 'nom_tags_pto_recogida_cars:destroy'
            && e !== 'nom_oficina_renta_cars:destroy'
            && e !== 'nom_suplemento_contrate_cars:destroy'
            && e !== 'nom_conditions_generals_cars:destroy'
            && e !== 'nom_tag_imagen_cars:destroy'
            && e !== 'nom_tipo_destino_hotels:destroy'
            && e !== 'nom_operador_hotels:destroy'
            && e !== 'nom_marca_asociada_hotels:destroy'
            && e !== 'nom_categorias_hotels:destroy'
            && e !== 'nom_intereses_hotels:destroy'
            && e !== 'nom_servicios_hotels:destroy'
            && e !== 'nom_habitacion_hotels:destroy'
            && e !== 'nom_regimen_alimenticio:destroy'
            && e !== 'nom_edad_hotels:destroy'
            && e !== 'nom_tag_imagen_hotels:destroy'
            && e !== 'nom_suplemento_contrate_hotels:destroy'
            && e !== 'nom_reducciones_contrate_hotels:destroy'
            && e !== 'nom_conditions_generals_hotels:destroy'
            && e !== 'nom_categories_conditions:destroy'
            && e !== 'tipo_afiliado:destroy',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresFlightDelete = false
        this.sourcePermisos[this.index].all.checkboxNomencladoresCarsDelete = false
        this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsDelete = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladores = !this.$store.state.app.showNomencladores
        this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      }, 10)
    },
  },
}
</script>
